#playbooks {
  .main__content & {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 !important;
    padding-bottom: 32px;
    margin: 0;
    overflow: hidden;
  }

  .playbooks-role{
    padding: 2px 0;
    position: relative;
    &:after {
     content: '';
     display: block;
     position: absolute;
     left: -12px;
     right: -12px;
     bottom: -12px;
     height: 1px;
     background: rgba(36, 41, 49, 0.25);
    }
  }

  .secondary-sidebar{
      padding: 0;
      z-index: 10;

      .playbooks-sidebar-title{
        padding-top: 8px;
      }
  }

  .secondary-sidebar-container{
      height: 100%;
      position: relative;

      .page-heading{
          width: 100%;
          margin: 0;
      }
  }

  .playbook-header{
    position: absolute;
    left: 240px;
    top: 0;
    right: 0;
    height: 64px;
    padding: 8px 24px;
    border-bottom: 1px solid rgba(36, 41, 49, 0.25);
    background: white;
    display: flex;
    align-items: center;
  }

  iframe {
    width: 100%;
    height: 100%;
  }

  #my-superset-container {
    height: 100%;
    width: 100%;
  }

  .data-team{
    margin: 12px 0;
    padding: 0 12px;
    width: 240px;
    border-right: 1px solid var(--border-light);
  }
}
