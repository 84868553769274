.iframe__wrapper{
  margin-top: 0 !important;
}

.superset{
  &__header{
    background: #070049;
    height: 88px;
    position: fixed;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 10px 20px;
    align-items: center;
    z-index: 2;
    justify-content: space-between;
    &__leftGroup{

    }

    &__rightGroup{
      display: flex;
      height: 40px;
      .button__wrapper{
        background-color: #c86dd7;
        border-radius: 10%;
        margin: 0;
        width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;

      button{
          color: #070049;
        }
      }
    }

  }

  &__logo{
    background: url('../../shared/images/fello-white.png') transparent center center no-repeat;
    background-size: 38px;
    border: none;
    cursor: pointer;
    height: 40px;
    visibility: visible;
    opacity: 1;
    outline: 0;
    width: 40px;
  }
}


.drawer.close{
  height: 100%;
}

.superset__open{
  padding: 0 !important;
  margin-bottom: 0 !important;
}

.superset__open__header{
  display: none;
}