@import '../shared/scss/styles.scss';

@font-face {
  font-family: 'karla';
  src: url('../shared/fonts/karla-regular-webfont.woff2') format('woff2'), url('../shared/fonts/karla-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'karla';
  src: url('../shared/fonts/karla-italic-webfont.woff2') format('woff2'), url('../shared/fonts/karla-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'karla';
  src: url('../shared/fonts/karla-bold-webfont.woff2') format('woff2'), url('../shared/fonts/karla-bold-webfont.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'karla';
  src: url('../shared/fonts/karla-bolditalic-webfont.woff2') format('woff2'), url('../shared/fonts/karla-bolditalic-webfont.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'karla';
  src: url('../shared/fonts/Karla-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'karla';
  src: url('../shared/fonts/Karla-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('../shared/fonts/Roboto-Medium.ttf') format('ttf');
  font-weight: 500;
  font-style: normal;
}

body {
  font-family: $sans-serif;
  min-height: 100vh;
}

body #root {
  background: #f5f6fa;
}

main {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  width: 100%;
}
