.call-log {
  padding: 12px 12px 24px;
  &__heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
  &__title {
    font: var(--type-h4);
    color: var(--content-primary);
  }
  &__list {}

  .adagio-button {
    margin-left: auto;
  }
}


// Call Event
.call-event {
  width: 100%;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  padding: 8px;
  background: var(--background-200);
  gap: 8px;
  align-items: flex-start;
  flex-wrap: wrap;
  &__title {
    flex: 1;
    word-break: break-all;
  }
  &__buttons {
    display: flex;
    flex-direction: row;
    gap: 4px;
  }

  &__detail {
    width: 100%;
    padding: 0 0 0 40px;
  }

  &--style-danger {
    background: var(--alert-danger-subtle);
  }
  &--style-info {
    background: var(--alert-info-subtle);
  }
  &--style-warning {
    background: var(--alert-warning-subtle);
  }
}
