.group {
  &__link {
    cursor: pointer;
    display: inline-block;
    margin: 0 5px 0 0;
    text-decoration: underline;
  }

  &__display {
    display: flex;
    flex-direction: column;
  }

  &__icon:hover {
    cursor: pointer;
  }
}

.group_page__padding {
  padding: 24px 0 0;
}