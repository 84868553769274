// Metrics
.call-metrics {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 40px;
  padding: 0 24px 16px;
  border-bottom: 1px solid var(--border-light);


  &__metric {
    display: flex;
    flex-direction: column;
  }
  &__metric-stat {
    font: var(--type-stat-large);
    color: var(--content-primary);
  }
  &__metric-label {
    font: var(--type-small);
    color: var(--content-secondary);
  }
}
