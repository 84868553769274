@import '../../../shared/scss/mixins';
@import '../../../shared/scss/variables';

.filter {
  &__wrapper {
    align-content: flex-start;
    align-items: center;
    border-radius: 4px;
    display: flex;

    border: solid 1px map-get($colors, mystic);
    padding: 16px 12px;
    &--chip {
      margin: 0 8px 0px 0px;
    }

    > .MuiFormControl-root {
      width: auto;
      min-width: 100px;
    }

    .MuiChip {
      &-deleteIcon {
        color: map-get($colors, tuna);
      }
    }

    .MuiFormControl-root {
    }
  }

  &__options {
    justify-content: space-between;
    align-content: flex-start;
  }

  &__results {
    margin: 20px 0;
  }

  /* selects */

  &__formControl {
    align-items: center;
    background: map-get($colors, mystic);
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    margin: 0 10px 0 0;
    padding: 8px 12px;

    .filter__formSelect {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .MuiSelect-select {
        padding: 0;
        align-items: center;
        @include Tag-Style;
        background: url('../../../shared/images/select-icon.png') transparent right center no-repeat;
        background-size: 8px auto;
        cursor: pointer;
        display: flex;
        padding-right: 20px;
      }
    }

    svg {
      display: none;
    }
  }

  /* checkbox */

  &__checkboxList {
    flex: 1 1 100%;
    display: inline-flex;
    margin-right: 24px;
  }

  &__checkboxListTitle {
    margin-left: 7px;
    margin-bottom: 8px;
    font-size: rem-calc(14);
    text-align: left;
  }

  &__resetLink {
    margin-left: 16px;
    font-size: rem-calc(12);
    cursor: pointer;
  }

  &__checkboxFormGroup {
    margin-top: 8px;
  }
  &__checkboxFormControl {
    margin: 0px;
  }
  &__checkboxFormControlLabel {
    font-size: rem-calc(15);
    margin-left: 8px;
  }
  &__checkboxIcon {
    width: 32px;
    height: 32px;
  }

  /* textField */
  &__textFieldRoot {
    display: flex;
    margin-top: 16px;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
  &__textFieldFormControl {
    flex: 1 1 calc(50% - 24px);
    margin-right: 24px;
    margin-bottom: 24px;
  }
}

.table {
  &__header {
    position: relative;
    .MuiInputAdornment-root {
      @include Vertical-Align;
      height: 20px;
      position: absolute;
      right: 20px;
      width: 20px;
    }
  }

  &__wrapper {
    overflow: auto;
    padding: 20px;
    box-sizing: border-box;
    background: white;

    .MuiTable-root {
      background: white;
      table-layout: fixed;
    }

    .MuiTableRow-root {
      border-bottom: 1px solid #e6e9f4;
      background: white;
      border-top: 1px solid #e6e9f4;

      &:nth-child(odd) {
        background: white;
      }

      &.tablerow__expanded {
        border: 0;
      }

      .MuiTableRow-root {
        border: 0;
      }
    }
  }

  &__long-field {
    word-wrap: break-word;
  }
}

.sort {
  &__wrapper {
    align-items: center;
    cursor: pointer;
    display: flex;
    outline: 0;
  }

  &__label {
    &--active {
    }
  }
}

.toolbar {
  &__wrapper {
    align-items: flex-end;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    justify-content: center;
    margin: 0 0 30px;
  }

  &__hidden_search {
    display: grid;
    justify-content: flex-end;
    margin: 0 0 30px;
  }
}

.searchTable {
  &__wrapper {
    grid-column: span 6;

    @include breakpoint(md) {
      grid-column: span 5;
    }
  }
  &__hide {
    display: none;
  }
}

.datatableActions {
  &__wrapper {
    .textfield-bold {
      display: block;
    }
  }
}

.actions {
  &__toolbar {
    display: flex;
    grid-column: span 6;
    justify-content: flex-start;
    margin-left: 20px;
    @include breakpoint(md) {
      grid-column: span 4;
    }
  }

  &__wrapper {
    position: relative;
    width: 100%;

    .MuiInputLabel-outlined {
      @include Tag-Style;
    }

    @include breakpoint(lg) {
      width: 200px;
    }

    .actions__list {
      .actions__item {
        font-size: rem-calc(14);
        justify-content: flex-start;
        padding: 0;

        svg {
          margin-right: 16px;
        }

        &:hover {
          background: map-get($colors, white);
          border-radius: 0;
        }
      }
    }
  }
}
button.MuiIconButton-root {
  font-size: rem-calc(14);
  justify-content: flex-start;
  padding: 0;

  svg {
    margin-right: 0;
  }

  &:hover {
    background: transparent;
  }
}
button.MuiIconButton-root.actions__item {
  font-size: rem-calc(14);
  justify-content: flex-start;
  padding: 0;

  svg {
    margin-right: 16px;
  }

  &:hover {
    background: transparent;
  }
}
.select {
  &__button {
    align-items: center;
    @include Tag-Style;
    background: url('../../../shared/images/select-icon.png') transparent right center no-repeat;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
    background-size: 8px auto;
    margin-left: auto;
  }
}

.checkbox-blue {
  svg {
    fill: map-get($colors, dark-blue);
    .Mui-checked & {
      fill: map-get($colors, dark-blue);
    }
  }
}

.modal {
  &__content {
    .MuiPaper-root {
      box-shadow: none;
    }
  }
}

.textfield-breakword {
  word-break: break-word;
}
