.audio {
  &__button {
    display: inline-flex;
    grid-row: span 2;
    align-items: center;
  }
}
.rowDiv__empty__display {
  padding-left: 50px;
  margin-bottom: 20px;
}

.audio__quality__visualiser__inner__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.audio__quality__visualiser__wrapper {
  padding: 0;
  margin-bottom: 20px;
}

.stat__circle {
  color: #06a561;
  background: #c4f8e2;
  font-size: 14px;
  padding: 4px 6px;
  border-radius: 4px;
  margin-bottom: 16px;
  z-index: 1;
}

.stat__yellow {
  background: #fff4c9;
  color: #f99600;
  font-size: 14px;
  padding: 4px 6px;
  border-radius: 4px;
  margin-bottom: 16px;
}

.stat__red {
  background: #fcd5d9;
  color: #f0142f;
  font-size: 14px;
  padding: 4px 6px;
  border-radius: 4px;
  margin-bottom: 16px;
}

.yellow,
.red {
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  height: 75px;
  width: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-color: #4cae4c;
  background-color: #a5d6a7;
}

.yellow {
  border: 2px solid yellow;
  background-color: #eacf7d;
}

.red {
  border: 2px solid red;
  background-color: #d9534f;
}

.audio__quality__visualiser__wrapper {
  #waveform_ref_timeline,
  #waveform_connect_to_agent_timeline,
  #waveform_agent_to_connect_timeline,
  #waveform_connect_to_customer_timeline,
  #waveform_customer_to_connect_timeline {
    display: none;
  }
  .visualiser {
    &__wrapper {
      display: grid;
      grid-template-columns: 31px 1fr;
      .play__button {
        width: 20px;
        height: 20px;
        margin-right: 30px;
      }
      wave {
        // Need to override scrollbar here
        overflow: hidden !important;
      }
    }

    &__slider {
      display: none;
    }

    &__title {
      display: none;
      margin: 0;

      a.textfield-bold {
        width: 20px;
        height: 20px;
        border: solid 2px blue;
        border-radius: 50%;
        text-indent: 100%;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    &__graph {
      margin: 0;
      width: 100%;
    }
  }
  .arrow {
    &__wrapper {
      position: relative;
      svg {
        position: absolute;
      }
    }
  }
  .audio__quality__audioLatency {
    &__wrapper {
      width: 100%;
      &__inner {
        width: 100%;
        display: grid;
        grid-template-columns: auto 1fr auto 1fr auto;
        grid-gap: 10px;
        align-items: center;
        margin-bottom: 20px;
        background-color: white;
        padding: 10px;

        .audioLatency__inner__column {
          display: flex;
          flex-direction: column;
          align-items: center;

          &--arrows {
            border-top: 1px solid;
            border-bottom: 1px solid;
          }
          &__latencyCircle {
            border-radius: 50%;
            border-width: 1px;
            border-style: solid;
            height: 85px;
            width: 85px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            border-color: #4cae4c;
            background-color: #a5d6a7;
            margin-bottom: 10px;
          }

          .arrows {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            &__reversed {
              transform: rotate(180deg);
            }

            .MuiSvgIcon-root {
              width: 2em;
              height: 2em;
            }
          }
        }
      }
    }
  }
}

.echo__latency__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;

  svg {
    position: relative;
    top: -35px;
    z-index: 0;
  }
}

.label__display {
  padding-left: 50px;
}

.globalAverage {
  &__wrapper {
    margin-top: 10px;
    display: flex;
    justify-content: space-evenly;
    align-items: baseline;
  }
}

.columnDiv {
  &__visualiser {
    padding: 5px;
    background: #ffffff;
    min-width: 343px;
    box-shadow: 0 1px 4px 0 rgba(21, 34, 50, 0.08);

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
    }
  }
  &__loss {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}

.info__box {
  background-color: grey;
  padding: 5px;
  border-radius: 10px;
  position: absolute;
  top: -30px;
  right: 0px;
}

.title__circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;

  &.avatar-icon {
    background: url('../../../../shared/images/avatar-icon.png') transparent center center no-repeat;
    background-size: contain;
    width: 81px;
    height: 81px;
  }

  &.phone-icon {
    background: url('../../../../shared/images/phone-icon.png') transparent center center no-repeat;
    background-size: contain;
    width: 81px;
    height: 81px;
  }
  &.person-icon {
    background: url('../../../../shared/images/person-icon.png') transparent center center no-repeat;
    background-size: contain;
    width: 81px;
    height: 81px;
  }
}

#waveform_Inbound_Reference_timeline, #waveform_Outbound_Reference_timeline {
  display: none;
}
