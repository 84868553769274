.report {
  &__wrapper {
    margin: 46px 0;
  }

  &__title {
    font-family: 'Karla';
    font-size: 16px;
    color: #131523;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 20px;
  }

  &__caption {
    background: #131523;
    color: white;
    padding: 4px 6px;
    font-size: 12px;
  }

  &__loss {
    font-family: 'Karla';
    font-size: 14px;
    color: #131523;
    letter-spacing: 0;
    text-align: center;
    line-height: 16px;
    font-weight: bold;
    text-transform: uppercase;
  }
}
