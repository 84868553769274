@import './mixins';
$sans-serif: 'karla', Helvetica, Verdana, sans-serif;

h1 {
  font-family: Karla;
  font-size: rem-calc(36);
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.9px;

  color: map-get($colors, black);
  margin: 0;
}

h2,
.h2 {
  font-family: Karla;
  font-size: rem-calc(28);
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -1px;
  color: map-get($colors, shark);
  margin: 0;
}

h3,
.h3 {
  font-family: Karla;
  font-size: rem-calc(20);
  font-size: rem-calc(20);
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: rem-calc(30);
  letter-spacing: -0.3px;
  color: map-get($colors, tuna);
  margin: 0;
}

.margin {
  &-right {
    margin-right: auto;
  }
}

.hidden {
  display: none;
}

.no-padding {
  padding: 0;
}
