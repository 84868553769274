// Title layout
.call-list {
  width: 100%;
  &__heading {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;
  }
  &__title {
    font: var(--type-h4);
    color: var(--content-primary);
  }
  &__description {
    font: var(--type-paragraph-small);
    color: var(--content-secondary);
    margin: 0 0 16px;
  }
  &__list {}
}


// Container
.report-container {
  display: flex;
  flex-direction: column;
  &--style-stacked {
    gap: 8px;
  }
  &--style-list {
    border: 1px solid var(--border-standard);
    border-radius: 6px;
    overflow: hidden;
    box-shadow: var(--elevation-shadow-100);
  }
}


.linked-report {
  cursor: pointer;
  position: relative;
  width: 100%;
  background: white;
  user-select: none;
  &:hover {
    background: var(--interactive-hover);
  }
  &:active {
    background: var(--interactive-active);
  }
  &:focus-visible {
    outline: 2px solid var(--focus-200);
    outline-offset: -2px;
  }

  &--style-list {
    padding: 8px 40px 8px 12px;
    border-bottom: 1px solid var(--border-light);
    display: flex;
    flex-direction: row;
    &:last-child {
      border-bottom: none;
    }
    .linked-report__reportee,
    .linked-report__id {
      width: 160px;
    }
  }

  &--style-stacked {
    padding: 12px;
    border-radius: 6px;
    box-shadow: var(--elevation-shadow-100);
    border: 1px solid var(--border-light);
  }

  &__title {
    flex: 1;
    font: var(--type-medium);
    color: var(--text-primary);
    padding: 0 24px 0 0;
  }
  &__detail {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
  }
  &__reportee,
  &__id {
    text-transform: uppercase;
    font: var(--type-small);
    color: var(--content-tertiary);
  }
  &__icon {
    position: absolute;
    top: 8px;
    right: 8px;
  }
}



