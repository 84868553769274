@import '../../shared/scss/variables';
@import '../../shared/scss/mixins';

.badge {

  span{
    top: 10px;
    right: 10px;
  }

  a{
    padding: 6px 16px;
  }

  .MuiBadge-badge{
    background: linear-gradient(101deg, #3023ae, #c86dd7);
  }
}
