@import '../../shared/scss/variables';
@import '../../shared/scss/mixins';
.main {
  &__wrapper {
    display: flex;
    height: 100vh;
    overflow: hidden;
    width: 100%;
  }

  &__content {
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;

    @include breakpoint(lg) {
      padding: 40px;
    }

    .datatable {
      box-shadow: none;
    }
  }
}
