.insights {
  &__wrapper {
    width: 100%;
    margin: 40px 0;

    .tabs__links {
      width: 100%;

      .MuiTabs-flexContainer {
        box-sizing: border-box;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        max-width: 100%;
        grid-gap: 30px;

        & + .MuiTabs-indicator {
          display: none;
        }

        .Mui-selected {
          background: #333752;
        }

        .MuiTab-root {
          padding: 20px 20px 30px;
          background: #ffffff;
          box-shadow: 0 1px 4px 0 rgba(21, 34, 50, 0.08);
          border-radius: 6px;
          min-width: 100%;
          max-width: 100%;
          position: relative;

          &.Mui-selected {
            background: #333752;

            .insights__title,
            .insights__text {
              color: white;
            }
          }
        }
      }
    }

    .tab__link {
      display: flex;
      width: 100%;

      .MuiTab-root {
        .MuiTab-wrapper {
          width: 100%;

          .insights__item {
            width: 100%;
            .highcharts-container {
              margin: 0 auto;
            }
          }
        }
      }
    }
  }

  &__scatter-chart {
    margin: 40px 0;

    .highcharts-container {
      max-width: 100%;
    }
  }

  &__filtertables {
    display: grid;
    grid-template-columns: 1fr 75%;
    grid-gap: 20px;
    margin: 40px 0;

    & > .filter__wrapper {
      background: #333752;
    }

    .filter__checkboxList {
      color: white;
      margin-right: 0;
      .filter__checkboxFormControl {
        display: flex;
      }
      .filter__checkboxFormControlLabel {
        margin: 0;
        padding: 0;
      }

      .MuiCheckbox-root {
        background-color: white;
      }

      .MuiSvgIcon-root {
        fill: white;
      }

      .filter__checked {
        .MuiSvgIcon-root {
          fill: #6e0bd4;
        }
      }
    }
  }

  &__item {
    width: 100%;
    .highcharts-container {
      margin: 0 auto;
      svg {
        max-width: 200px;
        object-fit: contain;
      }
    }
    .trigger__info {
      top: 10px;
      right: 10px;
    }
    .trigger__description {
      padding: 0;
      text-align: left;
      left: 20px;
      bottom: 10px;
    }
  }

  &__title {
    font-family: 'Karla';
    font-size: 16px;
    color: #131523;
    letter-spacing: 0;
    line-height: 24px;
    text-align: left;
  }
  &__text {
    font-family: 'Karla';
    font-size: 14px;
    color: #333752;
    letter-spacing: 0;
    line-height: 24px;
    text-align: left;
    text-transform: none;
  }
}
