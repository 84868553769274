.call-header {
  padding: 16px;
  display: flex;
  border-radius: 6px 6px 0 0;
  flex-direction: row;
  background: var(--background-100);
  position: relative;

  &__title {
    display: flex;
    flex: 1;
    direction: row;
    gap: 8px;
    align-items: center;
  }
  &__label {
    flex-shrink: 0;
    font: var(--type-small);
    padding: 6px;
    background: var(--background-200);
  }
  &__id {
    font: var(--type-medium-mono);
  }

  &__actions {
    display: flex;
    direction: row;
    gap: 4px;
  }
}