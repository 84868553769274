.aside-harmony-section--emphasis {
  border: 1px solid var(--alert-info-border);
}

.carousel-thumbnail {
  max-height: 105%;
  max-width: 105%;
}

.aside-harmony-text {
  font-size: 14px;
  line-height: 24px;
  margin-top: 4px;
  margin-bottom: 16px;
}

.aside-harmony-button {
  width: auto;
  margin-top: 8px;
  padding: 8px;
  font-size: 14px;
  line-height: 24px;
}

.aside-harmony-button__text {
  padding-left: 8px;
  padding-right: 8px;
  font-weight: 600;
}

.double-charts{
  padding: 16px 0;
  &__metric-chart{
    width: 280px;
    height: 100%;
    flex-shrink: 0;
  }
}