#ask {
    padding: 0.8rem 1.2rem;
    min-width: 10rem;
    width: 100%;
    font-size: 110%;
    border-radius: 0.2rem;
    border: 1px solid #ccc;
    transition: all 0.2s ease-in-out;  

}

#ask::placeholder {
    color: #aaa;
}

#ask:focus {
    border-color: rgba(107,62,255);
}

#ask:highlighted {
    border-color: rgba(107,62,255);
}