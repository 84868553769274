@import './variables';
@import './mixins';
.grid {
  &__container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 0 30px;
    height: 100%;
    width: 100%;

    &.auto-height {
      height: auto;
    }
  }

  &__sub {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0 30px;
    margin: 0 0 30px;
  }

  &__column {
    &--align-right {
      border-left: 1px solid map-get($colors, mystic);
    }
  }
  &__column--four {
    display: flex;
    flex-direction: column;
    grid-column: span 4;
    grid-column-start: 5;
    grid-column-end: 9;
    justify-content: center;
  }
  &__column--three {
    grid-column: span 3;
  }

  &__column--five {
    grid-column: span 5;
  }

  &__column--six {
    grid-column: span 6;
  }

  &__column--seven {
    grid-column: span 7;
  }

  &__column--eight {
    grid-column: span 8;
  }

  &__column--nine {
    grid-column: span 9;
  }

  &__column--ten {
    grid-column: span 10;
    grid-column-start: 2;
    grid-column-end: 12;
  }

  &__column--twelve {
    grid-column: span 12;
  }

  &__column--all {
    display: flex;
    flex-direction: column;
    grid-column: span 5;
    grid-column-start: 2;
    grid-column-end: 7;
    justify-content: center;
    margin-bottom: 0.5em;
    color: #3e3f42;
    font-size: 12px;
    font-family: Karla, sans-serif;
  }
}

/*
  AUTO GRID
  Set the minimum item size with `--auto-grid-min-size` =
  responsive grid with no media queries.
*/
.auto-grid {
  --auto-grid-min-size: 16rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
  grid-gap: 1rem;
  width: 100%;

  li {
    padding: 4rem 1rem;
    text-align: center;
  }
}

.wrapper {
  position: relative;
}
