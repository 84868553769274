@import '../../shared/scss/mixins';
@import '../../shared/scss/variables';
.toggle {
  &__wrapper {
    align-items: center;
    display: flex;
  }

  &__input {
    -webkit-appearance: none;
    -moz-appearance: none;
    position: relative;
    margin-right: 8px;
    text-transform: uppercase;

    &:focus {
      outline: 0;
    }

    &:not(:checked) {
      background: map-get($colors, weather);
      border-radius: 15px;
    }

    &:not(:disabled) {
      cursor: pointer;
    }

    &:before,
    &:after {
      content: '';
      display: inline-block;
      border-radius: 12px;
    }

    &:before {
      height: 24px;
      width: 48px;
    }
    &:after {
      background: map-get($colors, white);
      color: map-get($colors, black);
      content: attr(data-text-off);
      height: 22px;
      line-height: 22px;
      left: 2px;
      position: absolute;
      text-align: center;
      top: 2px;
      transition: left 0.1s ease-out;
      width: 28.235294117647058px;
    }

    &:checked {
      background-image: linear-gradient(118deg, #3023ae, #c86dd7);
      border-radius: 12px;
      &:after {
        background: map-get($colors, white);
        color: map-get($colors, black);
        content: attr(data-text-on);
        left: 18.764705882352942px;
      }
    }

    &:disabled {
      &:after {
        background: map-get($colors, porcelain);
      }
      &:checked {
        &:after {
          background-image: linear-gradient(118deg, #3023ae, #c86dd7);
        }
      }
    }
  }
}
