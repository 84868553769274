@import '../../shared/scss/variables';
@import '../../shared/scss/mixins';
.time {
  &__container {
    position: relative;
  }
  &__wrapper {
    display: none;
    &.open {
      background: map-get($colors, white);
      box-shadow: 0 5px 7px 0 map-get($colors, iron);
      display: block;
      min-width: 620px;
      position: absolute;
      top: 35px;
      z-index: 200;
    }

    &.right_align {
      right: 0;
    }
  }

  &__actions {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  &__header {
    align-items: center;
    border-bottom: 1px solid map-get($colors, porcelain);
    display: flex;
    justify-content: space-between;
    padding: 24px 30px 0;
  }

  &__content {
    display: none;
    &.open {
      background: map-get($colors, white);
      display: block;
      padding: 30px;
      position: relative;
    }
  }

  &__info {
    grid-column: span 2;
  }

  &__label {
    @include More-Info-Text;
    margin-right: 12px;
  }

  &__set {
    @include Button-Text;
  }
  &__link {
    cursor: pointer;
    display: inline-block;
    @include Date-Picker-Text;
    font-weight: normal;
    margin: 0 10px 0 0;
    text-align: left;
    white-space: nowrap;
    &.closed {
      display: none;
    }
  }
  &__results {
    font-weight: bold;
    text-decoration: underline;
  }
  &__btn {
    display: none;
    &.open {
      color: map-get($colors, tuna);
      cursor: pointer;
      display: block;
      font-size: rem-calc(20);
      position: absolute;
      right: 20px;
      top: 20px;
      z-index: 1;
    }
  }
}

.quick {
  &__wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
    width: 100%;
  }

  &__column {
    margin: 0;
    padding: 0;
  }

  &__item {
    @include Date-Picker-Text;
    list-style: none;
    padding: 3px 0;
  }

  &__link {
    @include Date-Picker-Text;
    text-decoration: none;
  }
}
.date {
  &__input {
    height: 30px;
  }
  &__label {
    grid-column: span 2;
    @include Date-Picker-Text;
    padding-bottom: 5px;
  }
}

.button {
  &__wrapper {
    display: flex;
    justify-content: flex-end;
    margin: 20px 0 0;
  }
}

.chevron-left {
  display: inline-block;
  border-right: 3px solid map-get($colors, storm-grey);
  border-bottom: 3px solid map-get($colors, storm-grey);
  width: 10px;
  height: 10px;
  transform: rotate(-225deg);
}

.chevron-right {
  display: inline-block;
  border-right: 3px solid map-get($colors, storm-grey);
  border-bottom: 3px solid map-get($colors, storm-grey);
  width: 10px;
  height: 10px;
  transform: rotate(-45deg);
}

.daterangepicker {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  @include Date-Picker-Text;
  position: relative;
}

.fromDateHourContainer {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 10px;
}

.timeSelect {
  &__container {
    margin-left: auto;
    select {
      @include Date-Picker-Text;
      background: white;
      height: 42px;
    }
  }
}

.multipleContentOnLine {
  position: relative;
  display: inline;
  padding: 1px;
}

.monthYear {
  &__container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
  }
  &__inner {
    select {
      @include Date-Picker-Text;
      background: white;
      border: 0;
      height: 42px;
      margin: 0 5px;
      -moz-appearance: none; /* Firefox */
      -webkit-appearance: none; /* Safari and Chrome */
      appearance: none;
    }
  }
}

.calendar {
  &__grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    text-align: center;
  }

  &__cell {
    align-items: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    height: 35px;
    width: 35px;

    &:hover {
      border-radius: 50%;
      color: rgb(255, 255, 255);
      background-image: linear-gradient(134deg, rgb(48, 35, 174), rgb(200, 109, 215));
    }

    &:focus {
      outline: none;
    }
  }
}
.daterangepickercontainer {
  position: relative;
}
.dateRangePickerChildren {
  display: none;
}

.fromDateHourContainer {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 10px;
}
.rangecontainer {
  width: 160px;
}

.rangebuttonstyle {
  cursor: pointer;
}

.rangebuttontextstyle {
  cursor: pointer;
  text-align: left;
  &:focus {
    outline: 0;
  }
}

.rangeButtonSelectedStyle {
  cursor: pointer;
  outline: 0;
  text-decoration: underline;
}

.dateTimeLabel {
  text-align: center;
  font-weight: bold;
  padding-bottom: 5px;
}

.calendarAddon {
  background-color: inherit;
}

.timeContainer {
  text-align: center;
  position: relative;
}

.timeSelectContainer {
  margin: 8px;
}

.timeIconStyle {
  position: absolute;
  top: 3px;
  left: 14px;
  font-style: normal;
  color: #555;
}

.multipleContentOnLine {
  position: relative;
  display: inline;
  padding: 1px;
}

.buttonContainer {
  position: absolute;
  display: flex;
  bottom: 0;
  right: 0;
  margin-right: 13px;
  margin-left: 13px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.buttonSeperator {
  padding-left: 5px;
  padding-right: 5px;
}

.applyButton {
  border-color: #4cae4c;
  color: #fff;
  font-size: rem-calc(12);
  border-radius: 3px;
  padding: 5px 10px;
  cursor: pointer;
  margin-right: 4px;
  border: 1px solid #5cb85c;
}

.cancelButton {
  background-color: #fff;
  color: #333;
  font-size: rem-calc(12);
  border-radius: 3px;
  padding: 5px 10px;
  cursor: pointer;
  border: 1px solid #ccc;
}

.maxDateLabel {
  padding: 7px;
  font-size: rem-calc(10);
}

.monthYearContainer {
  margin: 5px;
  margin-top: 15px;
  display: flex;
}

.leftChevron {
  display: grid;
  width: 25%;
  padding: 2px;
  justify-content: left;
  font-size: rem-calc(14);
}

.rightChevron {
  display: grid;
  width: 25%;
  padding: 2px;
  justify-content: right;
  font-size: rem-calc(14);
}

.activeNotifier {
  text-align: center;
  padding-bottom: 40px;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #12bc00;
  border-radius: 50%;
  display: inline-block;
}

.calendar {
  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }
}
