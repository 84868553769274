.users {
  &__link {
    cursor: pointer;
    display: inline-block;
    margin: 0 5px 0 0;
    text-decoration: underline;
  }
}

.account__activation {
    text-align: center;

    @-webkit-keyframes zoomIn {
      from {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
      }

      50% {
        opacity: 1;
      }
    }
    @keyframes zoomIn {
      from {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
      }

      50% {
        opacity: 1;
      }
    }
    .animate__zoomIn {
      -webkit-animation-name: zoomIn;
      animation-name: zoomIn;
      animation: zoomIn 1s;
    }
}
