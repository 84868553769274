.status__header{
  &__wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &__timeSection{
    display: flex;
    flex-direction: row;

    .worldTime{
      &__wrapper{
        &__London, &__Sydney, &__Seattle {
          display: grid;
          align-items: center;
          justify-content: space-evenly;
          margin-right: 50px;
          grid-template-columns: 60px 80px;
        }
      }

      &__clock{
        margin-right: 5px;
        .MuiSvgIcon-root{
          width: 2em;
          height: 2em;
        }
      }
    }

  }

  &__alertSection{
    .MuiSvgIcon-root{
      width: 2em;
      height: 2em;
      fill: red;
    }
  }
}

.timeFilter__wrapper{
  width: 15%;
}

.statusMap__wrapper{
  position: relative;

  .statusPopup{
    position: absolute;
    bottom: 0px;
    min-width: 500px;
    min-height: 200px;
    border: grey solid 1px;
    padding: 5px;
    box-shadow: 2px 2px 5px grey;
    background: white;
  }
}

.statusModal{
  &__wrapper{

  }
  &__header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    &__close{
      cursor: pointer;
    }
    &__alert{
      .MuiSvgIcon-root{
        fill: red;
      }
    }
    &__title{
      font-weight: 600;
      font-size: 18px;
    }
  }
  &__body{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    &__cxRow, &__axRow, &__serviceRow{
      display: grid;
      grid-template-columns: 200px 50px 200px 50px;
      column-gap: 10px;
      row-gap: 15px;
      &__tag{
        flex-wrap: wrap;
      }
    }
  }
  &__footer{
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    Button{
      margin-right: 10px;
    }
  }
}
