@import '../../shared/scss/mixins';
@import '../../shared/scss/variables';

.label {
  @include Card-Subtitle;
}

.wizard {
  &__wrapper {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }
  &__content {
    align-items: center;
    background: map-get($colors, white);
    display: flex;
    flex-grow: 1;
    justify-content: center;
    overflow-y: auto;
    width: 100%;
  }
  &__progress {
    &.MuiStepper-horizontal {
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      padding: 20px;
      position: relative;
      text-align: center;
      width: 100%;

      &:before {
        content: '';
        display: block;
        margin: 0 auto;
        width: calc(100% - 120px);
        height: 3px;
        background: linear-gradient(101deg, #3023ae, #c86dd7);
        left: 60px;
        position: absolute;
        transform: translate(0, -50%);
        right: 60px;
        top: 50%;
        z-index: 1;
      }
    }
  }
  &__btn-container {
    box-shadow: 0 -1px 0 0 #f3f3f6;
    display: flex;
    justify-content: space-between;
    padding: 16px 0 0;

    button:only-child {
      margin-left: auto;
    }
  }
  &__footer {
    @include Wizard-Footer-Text;
    padding: 30px 0;
    margin: 0 auto;
    text-transform: uppercase;
    text-align: center;

    span {
      @include Wizard-Footer-Link;
    }
  }
  &__form-group {
    display: grid;
    grid-template-columns: 60px 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
    margin-bottom: 25px;
  }

  .blueLabel {
    border-bottom: 4px solid map-get($colors, ultramarine-blue);
  }

  .pinkLabel {
    border-bottom: 4px solid map-get($colors, persian-pink);
  }
}

.step {
  align-items: center;
  border-radius: 50%;
  display: flex;
  font-size: rem-calc(14);
  justify-content: center;
  height: 86px;
  width: 86px;
  z-index: 2;

  &__active {
    background: linear-gradient(#3300ce, #3300ce) content-box, linear-gradient(#fff, #fff) padding-box, linear-gradient(125deg, #3023ae, #c86dd7) border-box;
    box-sizing: border-box;
    padding: 3px;
    border: solid 3px transparent;
    @include Step-Text-Active;
  }

  &__past {
    background: map-get($colors, ultramarine-blue);
    @include Step-Text-Past;
  }

  &__future {
    background: map-get($colors, white-lilac);
    border: 1px solid map-get($colors, ultramarine-blue);
    @include Step-Text-Future;
  }
}

// Needs to be removed at some stage

.wizardOptions {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto 1fr;
  padding: 24px 20px;
  position: relative;

  h3 {
    grid-column: 1 / 6;
    grid-row: 1 / 2;
  }
}

.cardContainer {
  position: relative;
}

.graph {
  margin-bottom: 25px;

  img {
    display: block;
    margin: 0 auto;
  }
}

.subscriptionType {
    position: absolute;
    top: 50%;
    margin-top: -50px;
    margin-left: -50px;
}
