@import '../../shared/scss/variables';
@import '../../shared/scss/mixins';

.visualiser {
  &__wrapper {
    display: flex;
    flex-direction: column;
    min-width: 280px;
    max-width: 100%;
  }

  &__label {
    text-align: right;
    margin-right: 20px;
    margin-left: auto;
  }

  &__title {
    margin: 40px 0 20px;
  }

  &__stats {
    margin-left: 10px;
  }
  &__graph {
    position: relative;
    margin-bottom: 20px;
    padding-left: 25px;
  }

  &__range {
    max-width: 300px;
  }

  &__slider {
    align-items: center;
    display: flex;
    margin-bottom: 20px;
  }
}
