/* styles for a solid line in the legend */
.dygraph-legend-line {
  display: inline-block;
  position: relative;
  bottom: .5ex;
  padding-left: 1em;
  height: 1px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  font: var(--type-chart-legend)
  /* border-bottom-color is set based on the series color */
}

/* styles for a dashed line in the legend, e.g. when strokePattern is set */
.dygraph-legend-dash {
  display: inline-block;
  position: relative;
  bottom: .5ex;
  height: 1px;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  /* border-bottom-color is set based on the series color */
  /* margin-right is set based on the stroke pattern */
  /* padding-left is set based on the stroke pattern */
}

.dygraphCustomLegend {
  padding-top: 12px;
  color: var(--content-primary);
  font: var(--type-chart-legend);
}

.dygraph-legend {
  margin-top: 0rem;
  margin-right: 0.5rem;
  position: absolute;
  font-size: 14px;
  z-index: 10;
  min-width: 90%;  /* labelsDivWidth */
  /*
  dygraphs determines these based on the presence of chart labels.
  It might make more sense to create a wrapper div around the chart proper.
  */
  top: -20px;
  right: 2px;
  background: rgba(255,255,255,0.7);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  line-height: normal;
  text-align: right;
  overflow: hidden;
  font-weight: normal !important;
  /* display: flex;
  flex-direction: row;
  align-items: flex-start; */

}

.dygraph-legend > span {
  margin-left: 1rem;
  display: inline-block;
  text-align: right;
}

.dygraphChart {
  position: relative;
  left: 1px;
  right: 1px;
  top: 1px;
  bottom: 1px;
  min-width: 100%;
  max-height: 240px;
}

.graph-container {
    position: relative;
}

.dygraphCustomLegend > div {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 8px;
  direction: row;
}


.dygraph-axis-label {
  color: var(--content-primary);
  font: var(--type-chart-axis);
  &-x {
    margin-top: 2px;
  }
  &-y {
    margin-right: 4px;
  }
}