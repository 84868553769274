.timeline-container {
  background: var(--background-100);
  padding: 24px 24px 0;
}

.vis-timeline {
  display: flex;
  flex-direction: row;
  height: 16px;
  border-radius: 6px;
  overflow: hidden;
  background: var(--background-200);
  &__bar {
    padding: 2px;
    &:before {
      content: '';
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 8px;
    }
  }
  &__bar--1 {
    background: var(--alert-info-muted);
    width: 30%;
    &:before {
      margin-left: 80%;
      background: var(--alert-info-emphasis);
    }
  }
  &__bar--2 {
    background: var(--alert-warning-muted);
    width: 10%;
    &:before {
      display: none;
    }
  }
  &__bar--3 {
    background: var(--alert-danger-muted);
    width: 40%;
    &:before {
      margin-left: 40%;
      background: var(--alert-danger-emphasis);
    }
  }
  &__bar--4 {
    background: var(--alert-info-muted);
    width: 20%;
    &:before {
      margin-left: 20%;
      background: var(--alert-info-emphasis);
    }
  }
}