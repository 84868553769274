@import '../../shared/scss/variables';
@import '../../shared/scss/mixins';

.breadcrumb {
  &__link {
    @include Breadcrumb-Text;
    font-weight: normal;
    padding-right: 10px;
    text-decoration: none;
    &:after {
      border-right: 2px solid map-get($colors, shark);
      border-bottom: 2px solid map-get($colors, shark);
      content: '';
      display: inline-block;
      height: 8px;
      margin-left: 8px;
      transform: rotate(-45deg);
      width: 8px;
    }
    &:hover {
      text-decoration: underline;
    }
    &--currentLink {
      cursor: pointer;
      font-weight: normal;
    }
  }
  &__text {
    @include Breadcrumb-Text;
  }
}
