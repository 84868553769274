.iframe {
  &__wrapper {
    height: 0px;
    margin-top: 40px;
    padding-bottom: 56.25%;
    position: relative;
    width: 100%;

    iframe {
      border: 0;
      height: 100%;
      left: 0;
      outline: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

.superset__iframe{
  margin-top: 0px !important;
  padding: 0;
  height: 100%;
}