@import '../../shared/scss/mixins';
@import '../../shared/scss/variables';

.btn {
  @include Button;
}
.btnSolid {
  @include Button-Solid;
}

.btnSolid-no-margin {
  margin-left: 0px !important;
}

.btnColoured {
  @include Button-Coloured;
}

.btnColoured-no-padding {
  @include Button-Coloured;

  padding: 0;
}

.btnColouredSmall {
  @include Button-Coloured-Small;

  &.MuiFormControl-root {
    .MuiInputBase-formControl {
      @include Button-Coloured-Small;
      padding: 0;
    }
  }
}

.btnSolidSmall {
  @include Button-Solid-Small;
}

.googleBtn {
  @include Button-Google;
}

.googleIcon {
  box-sizing: border-box;
  display: inline-block;
  height: 18px;
  margin: 0 8px;
  vertical-align: middle;
  width: 18px;
}

.btnClose {
  border: 0;
  color: map-get($colors, santas-grey);
  cursor: pointer;
  display: block;
  font-size: rem-calc(18);
  opacity: 0.5;
  outline: 0;
  padding: 0;
  z-index: 2;
  background: transparent;
}

.button {
  &__circle {
    display: none;
    height: 0;
    width: 0;
  }

  &--right {
    position: fixed;
    top: 121px;
    right: 81px;
    z-index: 1;

    //&.hide {
    // display: none;
    //}
    .navOpen & {
      right: 18px;
    }
  }
}

.button {
  @include Button-Fab;

  .button {
    &__circle {
      align-items: center;
      background: map-get($colors, white);
      border-radius: 50%;
      color: map-get($colors, electric-blue);
      font-size: rem-calc(18);
      display: flex;
      justify-content: center;
      height: 22px;
      margin-right: 10px;
      width: 22px;
    }
  }
}

.button:disabled {
}

.btnSolid:disabled {
}
