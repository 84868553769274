.error-state {
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: var(--background-200);
  padding: 24px;
  border-radius: var(--radius-m);
  border: 1px solid var(--border-light);
  &__icon {
    color: var(--alert-danger-emphasis);
    width: 24px;
    height: 24px;
  }
  &__message {
    font: var(--type-medium);
    color: var(--alert-danger-emphasis);
  }
}