table {
    thead {
        td {
            padding: 4pt 6pt;
            font-weight: bold;
        }
       
    }
    td {
        padding: 4pt 6pt;
        tr {
            td {
                width: 150pt
            }
        }
    }
}
  