@import '../../shared/scss/variables';
@import '../../shared/scss/mixins';

.search {
  &__wrapper {
    display: flex;
    position: relative;
  }
  &__container {
    .actions__grid & {
      display: grid;
      grid-template-columns: repeat(10, 1fr);
      position: absolute;
      left: 0;
      top: 20px;
      width: 100%;

      .search__wrapper {
        border-bottom: 1px solid map-get($colors, shark);
        grid-column-start: 6;
        grid-column-end: 10;
      }
    }
  }

  &__icon {
    position: absolute;
    right: 20px;
  }
}
