@import '../../shared/scss/mixins';
@import '../../shared/scss/variables';
.campaigns {
  &__name {
    @include Campaign-Type;
    position: absolute;
    bottom: 12px;
  }
  &__link {
    cursor: pointer;
    display: inline-block;
    margin: 0 5px 0 0;
    text-decoration: underline;
  }
  &__logo {
    display: block;
    width: 70px;
    height: auto;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px 80px;
    padding: 15px 0px 20px;
  }

  &__wizard_padding {
    grid-gap: 40px 80px;
    padding: 20px 80px 20px;
  }

  &__file_upload {
    min-height: 100px !important;
  }

  &__cell {
    align-items: center;
    background: map-get($colors, white);
    border-radius: 3.8px;
    border: 1.5px solid white;
    box-shadow: 0 2px 4px 0 rgba(202, 202, 202, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px 30px;
    position: relative;

    img {
      display: block;
      width: 70px;
      height: auto;
    }

    &:hover {
      border-radius: 3.8px;
      box-shadow: 0 3px 5px 0 rgba(155, 155, 155, 0.57);
      border-style: solid;
      border-width: 1.5px;
      border-image-source: linear-gradient(125deg, #3023ae, #c86dd7);
      border-image-slice: 1;
      background: rgba(109, 65, 190, 0.18);
    }

    &.selected {
      border-radius: 3.8px;
      box-shadow: 0 3px 5px 0 rgba(155, 155, 155, 0.57);
      border-style: solid;
      border-width: 1.5px;
      border-image-source: linear-gradient(125deg, #3023ae, #c86dd7);
      border-image-slice: 1;
      background: rgba(109, 65, 190, 0.18);
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
      &:before {
        background-image: linear-gradient(rgba(202, 202, 202, 0.5), rgba(202, 202, 202, 0.3));
        content: '';
        position: absolute;
        height: 100%;
        left: 0;
        top: 0;
        width: 100%;
      }
    }
  }
}

.campaignType {
  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 40px 80px;
    padding: 20px 80px 20px;
  }

  &__cell {
    align-items: center;
    background: map-get($colors, white);
    border-radius: 3.8px;
    border: 1.5px solid white;
    box-shadow: 0 2px 4px 0 rgba(202, 202, 202, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px 30px;
    position: relative;

    &.disabled {
      opacity: 0.5;
      &:before {
        background-image: linear-gradient(rgba(202, 202, 202, 0.5), rgba(202, 202, 202, 0.3));
        content: '';
        position: absolute;
        height: 100%;
        left: 0;
        top: 0;
        width: 100%;
      }

      &:hover {
        background: map-get($colors, white);
        border-radius: 3.8px;
        border: 1.5px solid white;
        box-shadow: 0 2px 4px 0 rgba(202, 202, 202, 0.5);
      }
    }

    &:hover {
      border-radius: 3.8px;
      box-shadow: 0 3px 5px 0 rgba(155, 155, 155, 0.57);
      border-style: solid;
      border-width: 1.5px;
      border-image-source: linear-gradient(125deg, #3023ae, #c86dd7);
      border-image-slice: 1;
      background: rgba(109, 65, 190, 0.18);
    }

    &.selected {
      border-radius: 3.8px;
      box-shadow: 0 3px 5px 0 rgba(155, 155, 155, 0.57);
      border-style: solid;
      border-width: 1.5px;
      border-image-source: linear-gradient(125deg, #3023ae, #c86dd7);
      border-image-slice: 1;
      background: rgba(109, 65, 190, 0.18);
    }
  }

  &__radio {
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  &__name {
    @include Campaign-Type;
  }

  &__logo {
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
    margin-bottom: 40px;
    height: 70px;
    width: 70px;
  }
}

.trigger {
  &__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 40px;
    padding: 20px;
  }

  &__cell {
    align-items: center;
    background: map-get($colors, white);
    border-radius: 3.8px;
    border: 1.5px solid white;
    box-shadow: 0 2px 4px 0 rgba(202, 202, 202, 0.5);
    display: flex;
    flex-direction: column;
    position: relative;

    &:hover {
      border-radius: 3.8px;
      box-shadow: 0 2px 4px 0 rgba(202, 202, 202, 0.5);
      border-style: solid;
      border-width: 1.5px;
      border-image-source: linear-gradient(125deg, #3023ae, #c86dd7);
      border-image-slice: 1;
      background: rgba(109, 65, 190, 0.18);
    }

    &.selected {
      border-radius: 3.8px;
      box-shadow: 0 2px 4px 0 rgba(202, 202, 202, 0.5);
      border-style: solid;
      border-width: 1.5px;
      border-image-source: linear-gradient(125deg, #3023ae, #c86dd7);
      border-image-slice: 1;
      background: rgba(109, 65, 190, 0.18);
    }
  }

  &__info {
    border: 0;
    outline: 0;
    height: 30px;
    background-image: url('../../shared/images/info.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-color: transparent;
    width: 30px;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
    cursor: pointer;
    padding: 0;
  }

  &__image {
    align-items: center;
    border-radius: 3.8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;
    position: relative;
    width: 100%;
    img {
      display: block;
      height: auto;
      max-width: 70px;
      width: 100%;
    }
  }
  &__title {
    @include Campaign-Type;
    margin-top: 20px;
  }

  &__description {
    @include Wizard-Description;
    @include fadein;
    @include Horizontal-Centre;
    padding: 5px 20px 10px;
  }
}

.configure {
  &__grid {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-gap: 10px 30px;
    padding: 20px 0;
  }

  &__icon {
    display: block;
    margin: 0 auto 30px;
    max-width: 88px;
  }

  &__cell {
    align-content: center;
    background: map-get($colors, white);
    grid-column: span 4;
    justify-content: center;
  }
}

.campaign {
  &__input {
    align-content: flex-start;
    display: flex;
    flex-direction: column;
    margin: 15px 0 0;
  }
}

.campaign-journey {
  display: flex;

  &__icon {
    align-items: center;
    background: map-get($colors, porcelain);
    border: solid 1px #eeeeee;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    height: 35px;
    padding: 5px;
    margin-right: 10px;
    position: relative;
    width: 35px;

    &:last-of-type {
      &:after {
        content: none;
      }
    }

    &:after {
      @include Vertical-Align;
      border-style: solid;
      border-width: 3.5px 0 3.5px 7px;
      border-color: transparent transparent transparent map-get($colors, tundora);
      content: '';
      right: -10px;
      width: 0;
      height: 0;
      position: absolute;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__number {
    align-content: center;
    background: map-get($colors, porcelain);
    border: solid 1px #eeeeee;
    display: flex;
    justify-content: center;
    height: 28px;
    margin: 0 10px;
    padding: 5px;
    position: relative;
    width: 28px;

    &:after {
      @include Vertical-Align;
      border-style: solid;
      border-width: 3.5px 0 3.5px 7px;
      border-color: transparent transparent transparent map-get($colors, tundora);
      content: '';
      right: -10px;
      width: 0;
      height: 0;
      position: absolute;
    }
  }
}

.campaign-description {
  &__name {
    @include Table-Text-Bold;
  }

  &__text {
    @include Table-Text-Description;
  }

  &__meta {
    @include Table-Text-Comment;
  }
}

.add-tag {
  &__wrapper {
  }
}

.graph {
  &__wrapper {
    padding-top: 15px;
  }
}
