.intercom-help-wrapper {
  position: fixed;
  bottom: 20px;
  right: 76px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    right: 48px;
  }
  to {
    opacity: 1;
    right: 76px;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    right: 76px;
  }
  to {
    opacity: 0;
    right: 48px;
  }
}

.intercom-fade-enter {
  opacity: 0;
  animation: fadeIn 0.5s ease-out forwards;
}

.intercom-fade-enter-active {
  opacity: 1;
}

.intercom-fade-exit {
  opacity: 1;
  animation: fadeOut 0.5s ease-out forwards;
}

.intercom-fade-exit-active {
  opacity: 0;
}
