@import '../../shared/scss/mixins';
@import '../../shared/scss/variables';

.header {
  &__wrapper {
    align-items: center;
    background: map-get($colors, white-lilac);
    //display: grid;
    //grid-template-columns: repeat(12, 1fr);
    justify-content: space-between;
    padding: 24px 24px 16px !important;
    border-bottom: 1px solid #c8c9cb;
    background-color: var(--background-100) !important;
    width: 100%;
    height: auto;

    .navOpen & {
      padding: 26px 18px;
    }

    &:before {
      background: linear-gradient(101deg, #3023ae, #c86dd7);
      content: '';
      display: block;
      height: 8px;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}
