@import './variables';
@import './mixins';

// Icons
// Using the flaticons stroke icon set
@font-face {
  font-family: 'Flaticons Stroke';
  src: url('../fonts/flaticons-stroke/flaticons-stroke.eot');
  src: url('../fonts/flaticons-stroke/flaticons-stroke.eot?#iefix') format("embedded-opentype"),
  url('../fonts/flaticons-stroke/flaticons-stroke.woff') format('woff'),
  url('../fonts/flaticons-stroke/flaticons-stroke.ttf') format('truetype'),
  url('../fonts/flaticons-stroke/flaticons-stroke.svg#flaticons-stroke') format('svg');
}

.icon-type {
  display: inline-block;
  height: 20px;
  width: 20px;
  &.type-Action {
    background: url('../images/mini-soak-icon.png') no-repeat left center;
    background-size: 20px 20px;
    padding: 0;
  }

  &.type-Avalanche {
    background: url('../images/mini-soak-icon.png') no-repeat left center;
    background-size: 20px 20px;
    padding: 0;
  }

  &.type-Ramp {
    background: url('../images/mini-messaging-icon.png') no-repeat left center;
    background-size: 20px 20px;
    padding: 0;
  }

  &.type-Soak {
    background: url('../images/mini-soak-icon.png') no-repeat left center;
    background-size: 20px 20px;
    padding: 0;
  }

  &.type-Full {
    background: url('../images/mini-messaging-icon.png') no-repeat left center;
    background-size: 20px 20px;
    padding: 0;
  }
}

.outcome-icon {
  &:before {
    content: '';
    border-radius: 50%;
    display: inline-block;
    height: 10px;
    margin-right: 8px;
    padding: 0;
    width: 10px;
  }

  &.outcome-Fail {
    &:before {
      background-color: map-get($colors, brilliant-rose);
    }
  }

  &.outcome-Pass {
    &:before {
      background-color: map-get($colors, sea-green);
      content: '';
    }
  }
}

.status-icon {
  &:before {
    content: '';
    border-radius: 50%;
    display: inline-block;
    height: 10px;
    margin-right: 8px;
    padding: 0;
    width: 10px;
  }

  &.status-On {
    &:before {
      background-color: map-get($colors, apple-green);
    }
  }
  &.status-Off {
    &:before {
      background-color: map-get($colors, pomegranate);
    }
  }

  &.status-Away {
    &:before {
      background-color: map-get($colors, marigold);
    }
  }

  &.status-Inactive {
    &:before {
      background-color: lightsalmon;
    }
  }

  &.status-Active {
    &:before {
      background-color: map-get($colors, sea-green);
      content: '';
    }
  }
}

.icon {
  &--lightning {
    display: block;
    width: 38px;
  }
  &--bin {
    &:before {
      background: url('../images/bin.png') no-repeat center center;
      background-size: 9.4px 10.3px;
      content: '';
      display: inline-block;
      height: 10px;
      margin-right: 6px;
      width: 10px;
    }
  }

  &--aws-logo {
    &:before {
      background: url('../images/aws-logo-icon.png') no-repeat center center;
      background-size: 9.4px 10.3px;
      content: '';
      display: inline-block;
      height: 10px;
      margin-right: 6px;
      width: 10px;
    }
  }
}

.chevron {
  display: inline-block;
  height: 20px;
  width: 20px;
  overflow: hidden;

  &:before {
    border-style: solid;
    border-width: 2px 2px 0 0;
    color: map-get($colors, tuna);
    content: '';
    display: inline-block;
    height: 14px;
    left: 3px;
    position: relative;
    top: 0;
    transform: rotate(-45deg);
    vertical-align: top;
    width: 14px;
  }
  &.bottom {
    &:before {
      top: 0;
      transform: rotate(135deg);
    }
  }
}
