@import '../../shared/scss/variables';
@import '../../shared/scss/mixins';

.audio-player {
  height: 100px;
  position: relative;
  width: 100px;
}

.audio-player:hover {
  cursor: pointer;
}

.audio-player-controls {
  position: absolute;
  width: 50px;
  height: 50px;
  left: 25px;
  top: 25px;
  text-align: center;
}

.audio-player-button {
  height: 20px;
}

.audio-player-back-ring {
  width: 90px;
  height: 90px;
  top: 5px;
  left: 5px;
  position: absolute;
  background-color: map-get($colors, shark);
  border-radius: 50%;
}

.audio-player-back-ring-center {
  width: 86px;
  height: 86px;
  top: 7px;
  left: 7px;
  position: absolute;
  background-color: white;
  border-radius: 43px;
}

.audio-player-progress-ring {
  width: 100px;
  height: 100px;
  top: 0px;
  left: 0px;
  position: absolute;
  border-radius: 50%;
  overflow: hidden;
}

.audio-player-progress-ring-center {
  width: 80px;
  height: 80px;
  top: 10px;
  left: 10px;
  position: absolute;
  background-color: white;
  border-radius: 50%;
}

.audio-player-progress-segment {
  position: absolute;
  background-image: linear-gradient(135deg, #3023ae, #c86dd7);
  width: 50px;
  height: 50px;
}

.audio-player-progress-segment-1 {
  top: 0;
  left: 50px;
  transform-origin: bottom left;
  background-image: linear-gradient(135deg, #3023ae, #c86dd7);
}

.audio-player-progress-segment-2 {
  top: 50px;
  left: 50px;
  transform-origin: top left;
  background-image: linear-gradient(135deg, #3023ae, #c86dd7);
}

.audio-player-progress-segment-3 {
  top: 50px;
  left: 0px;
  transform-origin: top right;
  background-image: linear-gradient(135deg, #3023ae, #c86dd7);
}

.audio-player-progress-segment-4 {
  top: 0;
  left: 0px;
  transform-origin: bottom left;
  background-image: linear-gradient(135deg, #3023ae, #c86dd7);
}

.note {
  max-width: 500px;
  margin: 0 auto;
}
.circle-audio-player {
  display: block;
  margin: 0 auto;
  cursor: pointer;
}
#playerContainer {
  padding: 24px;
}



.audio {
  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px 0px;
    position: relative;
    z-index: 100;
  }

  &__pause {
    box-sizing: content-box;
    display: block;
    width: 14px;
    height: 18px;
    border-left: 7px solid white;
    position: relative;
    z-index: 1;
    left: 9px;
    background-color: white;
    box-shadow: map-get($colors, shark) 7px 0px 0px 0px inset;
  }

  &__play {
    display: block;
    width: 0px;
    height: 0px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 20px solid white;
    position: relative;
    z-index: 1;
    left: 13px;
  }
  &__toggle {
    box-sizing: border-box;
    flex: 1 0 60px;
    position: relative;

    &--btn {
      box-sizing: border-box;
      position: absolute;
      left: 50%;
      margin-left: -20px;
      background-color: map-get($colors, shark);
      color: white;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      text-align: center;
      padding-top: 10px;
    }
  }
  &__progress {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    position: relative;
    flex: 10 0 auto;
    align-self: center;

    &--bar {
      box-sizing: border-box;
      width: 100%;
      height: 5px;
      left: 0px;
      background: rgb(228, 228, 228);
    }
  }

  &__info {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  &__volume {
    z-index: 20;
    cursor: pointer;
    position: relative;
    width: 0px;
    height: 0px;
    border-bottom: 15px solid rgb(228, 228, 228);
    border-left: 45px solid transparent;
  }
}
