.callReport__wrapper{

  .tabs__links{
    position: fixed;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    max-width: 1041px;
    min-width: 899px;

    .MuiTabs-scroller{
      overflow: hidden;
      height: 48px;
      max-height: 48px;
    }
  }

  .tab__content{
    padding-top: 96px;
    height: 100%;
    overflow-y: auto;

    .MuiTypography-root{
      overflow-y: auto;
      overflow-x: hidden;
      min-height: 802px;
    }
  }
}

.main__content{
  padding-top: 0 !important;
}