@import '../../shared/scss/mixins';
@import '../../shared/scss/variables';

.tabs {
  &__wrapper {
    position: relative;
    overflow: hidden;
    min-height: 850px;

    &--vertical {
      display: flex;
      flex-grow: 1;

      .tabs__label {
        text-transform: unset;
        margin-right: 20px;
      }
    }

    .tabs__links {
      margin: 0;
    }
  }

  &__nav {
    margin-right: 20px;
    border-right: 1px solid map-get($colors, mystic);
  }

  &__link {
    @include Tab-Text;

    &.active {
      color: map-get($colors, tuna);
    }
  }
}

.panel {
  &__content {
    .card {
      box-shadow: none;
      margin-bottom: 30px;
    }

    .textfield {
      margin-bottom: 30px;
    }

    .selectfield {
      margin-bottom: 30px;
    }
  }

  &__wrapper {
    width: 100%;
  }
}

.dropdown-helper-description {
  font-family: "Karla";
}