#raw-output-container {
    display: block;
    width: 100%;
    text-align: left;
    overflow-wrap: break-word;
}


#json-pretty {
  display: block;
  width: 100%;
  overflow-y: auto;
  border-radius: var(--radius-m);
  background: var(--background-200);
  border: 1px solid var(--border-light);
  
  code {
    font-family: var(--font-mono);
    line-height: inherit;
    font-size: 12px;
    line-height: 20px;
  }
  
  pre {
    width: 100%;
    white-space: pre-wrap;
    overflow: auto;
    display: block;
    background: var(--background-200);
    border-radius: 6px;
    font-size: 12px;
    line-height: 20px;
    font-family: var(--font-mono);
    padding: 12px;
  }
}


  #raw-output-container * {
    display: inline-block;
  }

  .__json-pretty__ {
    display: block!important;
    border: none;
    color: silver;
    //background: white;
    padding: 2rem;
    margin:0;
  }
  .__json-key__ {
    color: purple;
  }
  
  .__json-value__ {
    color: red;
  }
  
  .__json-string__ {
    color: blue;
  }
  
  .__json-boolean__ {
    color: green;
  }
  
  .__json-pretty-error__ {
    line-height: 1.3;
    color: red;
    //background: white;
    overflow: auto;
  }
  
