@import '../../shared/scss/variables';
@import '../../shared/scss/mixins';

.modal {
  &__wrapper {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    z-index: 500;

    &.open {
      align-items: center;
      display: flex;
      justify-content: center;
    }
  }

  &__inner {
    align-items: stretch;
    background: map-get($colors, white);
    border-radius: 5px;
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    max-height: 95%;
    max-width: 80%;
    min-height: 700px;
    position: relative;
    width: 1150px;
    z-index: 2;
  }

  &__small {
    height: 350px;
    width: 400px;
    overflow: auto;
    min-height: 0;
  }

  &__content {
    background: map-get($colors, white);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 24px;
    text-align: left;
    overflow-y: auto;
  }

  &__overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

  &__header {
    border-bottom: 1px solid map-get($colors, mystic);
    display: flex;
    justify-content: space-between;
    padding: 20px 24px;
  }
  &__btn {
    background: #408cec;
    border-radius: 3px;
    border: 0;
    color: #fff;
    display: block;
    padding: 12px 20px;
    margin: 0 auto;
    width: 150px;
  }
}
