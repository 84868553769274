@import '../../shared/scss/mixins';
@import '../../shared/scss/variables';

.card {
  border-radius: 4.8px;
  box-shadow: 0 5px 7px 0 #90a4b738;
  background: map-get($colors, white);
  max-width: 222px;
  position: relative;

  &--alternative {
    // margin: 0 auto;
    max-width: none;
    padding: 10px 10px;
    min-width: 350px;
    margin-top: 10px;
    margin-bottom: 15px;
  }

  &__media {
    margin: 0 auto;
    height: 75px;
    width: auto;
  }

  &__title {
    @include Card-Title;
    text-align: left;
    font-size: 14px;
  }

  &__description {
    @include Card-Subtitle;
    text-align: left;
  }
}
