@import '../../shared/scss/variables';
@import '../../shared/scss/mixins';

.pagination {
  &__wrapper {
    display: flex;
    flex-direction: row;
    padding: 0;

    .MuiIconButton-label {
      padding: 0;
    }
  }

  &__content {
    display: block;
    height: auto;
    margin: 0 auto;
    padding: 0;

    button {
      border-radius: 0;
      display: block;
      height: 38px;
      padding: 6px 10px;
      width: 38px;

      &:nth-child(1) {
        border-right: 1px solid map-get($colors, mystic);
        margin-right: 0;
      }

      &:nth-child(2) {
        border-left: 1px solid map-get($colors, mystic);
        margin-left: 0;
      }
    }

    svg {
      fill: map-get($colors, black);
      position: relative;
      top: auto;
    }
  }
  
}

.MuiTablePagination-actions {
  button {
    &:first-of-type {
      padding: 5px 10px 5px 5px;
    }

    &:last-of-type {
      padding: 5px 5px 5px 5px;
      margin-left: 25px;
    }
  }
}
