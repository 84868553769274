// REM font sizing
// Foundation for Sites by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source
////
/// @group functions
////
$global-font-size: 100% !default;

/// Removes the unit (e.g. px, em, rem) from a value, returning the number only.
///
/// @param {Number} $num - Number to strip unit from.
///
/// @returns {Number} The same number, sans unit.
@function strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

/// Converts one or more pixel values into matching rem values.
///
/// @param {Number|List} $values - One or more values to convert. Be sure to separate them with spaces and not commas. If you need to convert a comma-separated list, wrap the list in parentheses.
/// @param {Number} $base [null] - The base value to use when calculating the `rem`. If you're using Foundation out of the box, this is 16px. If this parameter is `null`, the function will reference the `$global-font-size` variable as the base.
///
/// @returns {List} A list of converted values.
@function rem-calc($values, $base: null) {
  $rem-values: ();
  $count: length($values); // If no base is defined, defer to the global font size

  @if $base==null {
    $base: $global-font-size;
  }

  // If the base font size is a %, then multiply it by 16px
  // This is because 100% font size = 16px in most all browsers
  @if unit($base) == '%' {
    $base: ($base / 100%) * 16px;
  }

  // Using rem as base allows correct scaling
  @if unit($base) == 'rem' {
    $base: strip-unit($base) * 16px;
  }

  @if $count==1 {
    @return -zf-to-rem($values, $base);
  }

  @for $i from 1 through $count {
    $rem-values: append($rem-values, -zf-to-rem(nth($values, $i), $base));
  }

  @return $rem-values;
}

// Converts a unitless, pixel, or rem value to em, for use in breakpoints.
@function -zf-bp-to-em($value) {
  // Pixel and unitless values are converted to rems
  @if unit($value) == 'px' or unitless($value) {
    $value: rem-calc($value, $base: 16px);
  }

  // Then the value is converted to ems
  @return strip-unit($value) * 1em;
}

/// Converts a pixel value to matching rem value. *Any* value passed, regardless of unit, is assumed to be a pixel value. By default, the base pixel value used to calculate the rem value is taken from the `$global-font-size` variable.
/// @access private
///
/// @param {Number} $value - Pixel value to convert.
/// @param {Number} $base [null] - Base for pixel conversion.
///
/// @returns {Number} A number in rems, calculated based on the given value and the base pixel value. rem values are passed through as is.
@function -zf-to-rem($value, $base: null) {
  // Check if the value is a number
  @if type-of($value) != 'number' {
    @warn inspect($value)+' was passed to rem-calc(), which is not a number.';
    @return $value;
  }

  // Transform em into rem if someone hands over 'em's
  @if unit($value) == 'em' {
    $value: strip-unit($value) * 1rem;
  }

  // Calculate rem if units for $value is not rem or em
  @if unit($value) != 'rem' {
    $value: strip-unit($value) / strip-unit($base) * 1rem;
  }

  // Turn 0rem into 0
  @if $value==0rem {
    $value: 0;
  }

  @return $value;
}

/// Converts a pixel, percentage, rem or em value to a unitless value based on a given font size. Ideal for working out unitless line heights.
///
/// @param {Number} $value - Value to convert to a unitless line height
/// @param {Number} $base - The font size to use to work out the line height - defaults to $global-font-size
///
/// @return {Number} - Unitless number
@function unitless-calc($value, $base: null) {
  // If no base is defined, defer to the global font size
  @if $base==null {
    $base: $global-font-size;
  }

  // First, lets convert our $base to pixels
  // If the base font size is a %, then multiply it by 16px
  @if unit($base) == '%' {
    $base: ($base / 100%) * 16px;
  }

  @if unit($base) == 'rem' {
    $base: strip-unit($base) * 16px;
  }

  @if unit($base) == 'em' {
    $base: strip-unit($base) * 16px;
  }

  // Now let's convert our value to pixels too
  @if unit($value) == '%' {
    $value: ($value / 100%) * $base;
  }

  @if unit($value) == 'rem' {
    $value: strip-unit($value) * $base;
  }

  @if unit($value) == 'em' {
    $value: strip-unit($value) * $base;
  }

  @if unit($value) == 'px' {
    @return strip-unit($value) / strip-unit($base);
  }

  @if unitless($value) and ($value > 10) {
    @return $value / strip-unit($base);
  }

  @return $value;
}

// Truncate text
@mixin truncateText($overflow: ellipsis) {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: $overflow;
}

@mixin center-block {
  display: block;
  margin-right: 0;
  margin-left: 0;
}

// Breakpoints
@mixin breakpoint($class) {
  @if $class==sm {
    @media (max-width: 420px) {
      @content;
    }
  } @else if $class==md {
    @media (min-width: 768px) {
      @content;
    }
  } @else if $class==lg {
    @media (min-width: 1024px) {
      @content;
    }
  } @else {
    @warn "Breakpoint mixin supports: xs, sm, md, lg";
  }
}

$colors: (
  transparent: transparent,
  white: #ffffff,
  white-lilac: #f8f8fd,
  mystic: #eaedf3,
  wild-sand: #f4f4f4,
  athens-grey: #e6eaee,
  porcelain: #e6eced,
  weather: #d8d8d8,
  marigold: #ffc900,
  bright-green: #00ff2d,
  apple-green: #7ed321,
  sea-green: #87e6d3,
  pacific-blue: #00baba,
  seafoam-blue: #66cccc,
  royal-blue: #0013ff,
  persian-pink: #ff33cc,
  bright-orange: #ff5a00,
  brilliant-rose: #ce0058,
  pomegranate: #ff0000,
  electric-blue: #3300ff,
  ultramarine-blue: #3300ce,
  ultramarine: #2e00ba,
  oxford-blue: #354052,
  dark-blue: #070049,
  iron: #ced0da,
  lilac: #64638e,
  regent-grey: #8a96a0,
  santas-grey: #9ea0a5,
  cool-grey: #a8a9ab,
  storm-grey: #74757a,
  aluminium: #efecf84f,
  tundora: #434343,
  tuna: #3e3f42,
  shark: #282d39,
  black: #000000,
  mui-table-black: #000000DD
);

// Mixin to create classes from colours, eg text--shark, background--shark

@mixin modifiers($map, $attribute, $prefix: '--', $separator: '-', $base: 'base') {
  @each $key, $value in $map {
    &#{if($key != $base, #{$prefix}#{$key}, '')} {
      @if type-of($value) == 'map' {
        @include modifiers($value, $attribute, $separator);
      } @else {
        #{$attribute}: $value;
      }
    }
  }
}

.text {
  @include modifiers($colors, 'color', $separator: ':');
}

.background {
  @include modifiers($colors, 'background', $separator: ':');
}

/* Fade In Animation */

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@mixin fadein {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}

.fade-in {
  @include fadein;
}

@mixin Horizontal-Centre {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
}

@mixin Absolute-Centre {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin Vertical-Align {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin Font-12 {
  font-family: Karla;
  font-size: rem-calc(12);
  line-height: 1.47;
}

@mixin Font-13 {
  font-family: Karla;
  font-size: rem-calc(13);
  line-height: 1.57;
}

@mixin Font-14 {
  font-family: Karla;
  font-size: rem-calc(14);
  line-height: 1.57;
}
@mixin Card-Title {
  font-family: Karla;
  font-size: rem-calc(21);
  font-weight: bold;
  letter-spacing: -0.5px;
  text-align: center;
  color: map-get($colors, shark);
}

@mixin Card-Subtitle {
  @include Font-14;
  letter-spacing: -0.1px;
  text-align: center;
  color: map-get($colors, regent-grey);
}

@mixin Button-Text {
  color: map-get($colors, black);
  @include Font-12;
  font-weight: bold;
  letter-spacing: 0.4px;
  text-align: center;
}

@mixin Button {
  border-radius: 5px;
  @include Font-12;
  font-weight: bold;
  letter-spacing: -0.2px;
  margin: 16px 0;
  outline: 0;
  padding: 10.5px 15px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  cursor: pointer;
}

@mixin Button-Coloured {
  background: linear-gradient(#fff, #fff) padding-box, linear-gradient(101deg, #3023ae, #c86dd7) border-box;
  border: 0.8px solid transparent;
  border-radius: 5px;
  color: map-get($colors, black);
  @include Font-12;
  font-weight: bold;
  letter-spacing: 0.4px;
  min-width: 180px;
  outline: 0;
  padding: 10.5px 15px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}

@mixin Button-Coloured-Small {
  background: linear-gradient(#fff, #fff) padding-box, linear-gradient(101deg, #3023ae, #c86dd7) border-box;
  border: 0.8px solid transparent;
  border-radius: 5px;
  color: map-get($colors, black);
  @include Font-12;
  font-weight: bold;
  letter-spacing: 0.4px;
  max-width: 90px;
  outline: 0;
  padding: 9px 15px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}

@mixin Button-Solid-Small {
  border: solid 1px map-get($colors, electric-blue);
  background-image: linear-gradient(to bottom, map-get($colors, ultramarine-blue), #27009c);
  border-radius: 5px;
  color: map-get($colors, white-lilac);
  @include Font-12;
  font-weight: bold;
  letter-spacing: 0.4px;
  outline: 0;
  padding: 10.5px 36px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}

@mixin Button-Solid {
  border: solid 1px map-get($colors, electric-blue);
  background-image: linear-gradient(to bottom, map-get($colors, ultramarine-blue), #27009c);
  border-radius: 5px;
  color: map-get($colors, white-lilac);
  @include Font-12;
  font-weight: bold;
  letter-spacing: 0.4px;
  min-width: 180px;
  outline: 0;
  padding: 10.5px 15px;
  text-align: center;
  text-transform: uppercase;
  width: auto;
  cursor: pointer;
}

@mixin Button-Fab {
  align-items: center;
  border: solid 1px map-get($colors, electric-blue);
  background-image: linear-gradient(to bottom, map-get($colors, ultramarine-blue), #27009c);
  border-radius: 30px;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
  color: map-get($colors, white);
  display: flex;
  font-weight: bold;
  @include Font-14;
  outline: 0;
  padding: 10.5px 15px;
  text-transform: uppercase;
  text-decoration: none;
  width: auto;
  cursor: pointer;
}

@mixin Button-Google {
  background: map-get($colors, white);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12), 0 4px 4px 0 rgba(0, 0, 0, 0.24), 0 0 11px 0 rgba(0, 0, 0, 0.12), 0 11px 11px 0 rgba(0, 0, 0, 0.24);
  border-radius: 2.7px;
  color: rgba(0, 0, 0, 0.54);
  font-family: Roboto;
  font-size: rem-calc(12);
  line-height: 23px;
  letter-spacing: 0.3px;
  padding: 10.5px 15px;
  min-width: 180px;
  outline: 0;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}

@mixin Breadcrumb-Text {
  @include Font-14;
  font-weight: bold;
  letter-spacing: -0.3px;
  color: map-get($colors, shark);
}

@mixin Tab-Text {
  @include Font-14;
  letter-spacing: -0.3px;
  text-align: center;
  color: map-get($colors, regent-grey);
}

@mixin Table-Head-Text {
  @include Font-12;
  font-weight: bold;
  color: map-get($colors, storm-grey);
}

@mixin Table-Text-Description {
  @include Font-14;
  line-height: 1.29;
  letter-spacing: -0.1px;
  color: map-get($colors, mui-table-black);
}

@mixin Table-Text-Bold {
  @include Font-14;
  font-weight: bold;
  letter-spacing: -0.1px;
  color: map-get($colors, mui-table-black);
}

@mixin Table-Text {
  @include Font-14;
  letter-spacing: -0.1px;
  color: map-get($colors, mui-table-black);
}

@mixin Table-Text-Comment {
  @include Font-12;
  font-style: italic;
  letter-spacing: -0.1px;
  color: map-get($colors, storm-grey);
}

@mixin Login-Text {
  font-family: Karla;
  font-size: rem-calc(18);
  font-weight: bold;
  line-height: 1.22;
  letter-spacing: -0.1px;
  color: map-get($colors, ultramarine-blue);
}

@mixin Tag-Style {
  @include Font-14;
  letter-spacing: -0.3px;
  color: map-get($colors, tuna);
}

@mixin Filter-Text {
  @include Font-14;
  letter-spacing: -0.3px;
  color: map-get($colors, tuna);
}

@mixin Step-Text {
  color: map-get($colors, white-lilac);
  @include Font-14;
  font-weight: bold;
  text-align: center;
}

@mixin Steps-Text-Future {
  color: map-get($colors, tuna);
  @include Font-14;
  font-weight: bold;
  text-align: center;
}

@mixin More-Info-Title {
  color: map-get($colors, shark-blue);
  font-family: Karla;
  font-size: rem-calc(21);
  font-weight: bold;
  letter-spacing: -0.5px;
}

@mixin More-Info-Text {
  color: map-get($colors, regent-grey);
  @include Font-14;
  line-height: 1.57;
  letter-spacing: -0.1px;
}

@mixin Form-Label {
  color: map-get($colors, shark);
  @include Font-14;
}

@mixin Date-Picker-Text {
  color: map-get($colors, shark);
  @include Font-14;
  font-weight: bold;
  text-align: center;
}

@mixin Highlighted-Text {
  color: map-get($colors, white-lilac);
  @include Font-14;
  letter-spacing: -0.3px;
}

@mixin Nav-Link {
  color: map-get($colors, white);
  @include Font-14;
  font-weight: bold;
}

@mixin Nav-Title {
  color: map-get($colors, santas-grey);
  @include Font-12;
}

@mixin Sub-Nav-Text {
  color: map-get($colors, white);
  @include Font-14;
}

@mixin Wizard-Title {
  color: map-get($colors, shark);
  font-family: Karla;
  font-size: rem-calc(28);
  font-weight: bold;
  letter-spacing: -1px;
  text-align: center;
}

@mixin Wizard-Footer-Text {
  color: map-get($colors, regent-grey);
  @include Font-12;
  font-weight: bold;
}

@mixin Wizard-Footer-Link {
  color: map-get($colors, ultramarine-blue);
  @include Font-12;
  font-weight: bold;
}

@mixin Code-Title {
  color: map-get($colors, oxford-blue);
  font-family: Karla;
  font-size: rem-calc(20);
  font-weight: bold;
  letter-spacing: -0.3px;
}

@mixin Code-Description {
  color: map-get($colors, storm-grey);
  @include Font-14;
  line-height: 1.57;
  letter-spacing: -0.2px;
}

@mixin Code-Link {
  color: map-get($colors, royal-blue);
  @include Font-14;
  font-weight: bold;
  text-align: right;
}

@mixin Calendar-Day {
  color: map-get($colors, regent-grey);
  @include Font-12;
  font-weight: bold;
  text-align: center;
}

@mixin Application-Name {
  color: map-get($colors, black);
  @include Font-14;
  font-weight: bold;
  text-align: center;
}

@mixin Campaign-Type {
  color: map-get($colors, black);
  @include Font-14;
  font-weight: bold;
  text-align: center;
}

@mixin Date-Disabled {
  color: map-get($colors, regent-grey);
  @include Font-14;
  font-weight: bold;
  text-align: center;
}

@mixin Wizard-Title {
  color: map-get($colors, tundora);
  @include Font-14;
}

@mixin Wizard-Description {
  color: map-get($colors, tundora);
  font-family: Karla;
  font-size: rem-calc(11);
}

@mixin Step-Text-Past {
  @include Font-13;
  text-align: center;
  color: map-get($colors, white-lilac);
}

@mixin Step-Text-Future {
  @include Font-13;
  text-align: center;
  color: map-get($colors, tuna);
}

@mixin Step-Text-Active {
  @include Font-13;
  text-align: center;
  color: map-get($colors, white);
}
