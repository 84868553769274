.answer {
    min-height: 28px;
}

.transcript {
    white-space: pre-line;
    text-align: left;
}

.reroll {
    display: inline-block;
    text-align: center;
    width: 5rem;
    border-radius: 0.3rem;
    margin-right: 1.5rem;
    cursor: pointer;
    padding: 0.4rem;
    border: 1px solid silver;
    color: #444;
    background-color: rgba(0,0,0,0.07);
    transition: all 0.2s ease-in-out;
}

.reroll:hover {
    border: 1px solid rgba(0,0,255,0.5);;
    color: blue;
    background-color: rgba(0,0,255,0.1);
}

.getmorex {
    color: blue;
    cursor: pointer;
}

.question {
    font-size: 100%;
    transition: all 0.2s ease-in-out;
    margin-bottom: 0.8rem;
}

.fade-in {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: 0.2s;
}

.fade-in-out {
	opacity: 1;
	animation-name: fadeInOpacity;
	animation-iteration-count: 1000;
	animation-timing-function: ease-in;
	animation-duration: 0.2s;
}

@keyframes fadeInOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.qanda {
    margin: 1rem 1rem;
    margin-top: 3rem;
    line-height: 1.2;
}

.captions {
    font-weight: normal;
    background-color: rgba(107,62,255,0.1);
    font-size: 12pt;
    color: #111;
    line-height: 1.678;
    padding-top: 0.321ex;
    padding-bottom: 0.321ex;  
  }
  
  .getmore {
    font-weight: normal;
    font-family: 'droid-sans-mono', monospace;
    background: transparent;
    font-size: 14pt;
    color: #111;
    line-height: 1.678;
    padding-top: 0.321ex;
    padding-bottom: 0.321ex;  
    color: blue;
    cursor: pointer;
}


.lds-dual-ring {
    display: inline-block;
    width: 18px;
    height: 18px;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 18px;
    height: 18px;
    margin: 2px;
    border-radius: 50%;
    border: 2px solid rgb(107,62,255);
    border-color: rgb(107,62,255) transparent rgb(107,62,255) transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }


.qc {
    background-color: rgba(107,62,255,0.1);
    font-size: 110%;
    line-height: 1.5;
    width: 100%;
    padding: 10px;
    margin: 0px;
    margin-top: 1rem;
    border-radius: 8px;
}


.qq-icon {
    opacity: 0.5;
    min-width: 24px;
    height: 24px;
    background-color: transparent;
    margin-right: 8px;
    border-radius: 4px;
    padding: 2px 0;
}

.qq {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0px 0px;
    padding: 8px 8px;
    border-radius: 4px;
    background-color: white;
    font-style: italic;
}

.qa > .qq-icon {
    margin-top: 4px;
}

.qa {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 0px 0px;
    padding: 8px 8px;
    padding-bottom: 0;
    min-height: 44px;
}

.qq-content {
    white-space: pre-line;
    min-height: 24px;
    padding: 6px 0px;
}

.qq-action {
    margin-left: auto;
    padding-left: 12px;
}
