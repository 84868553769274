.rowDiv-callReport-agentNetwork {
    margin-top: 2em;
    vertical-align: top;
    height: 30%;
    margin-right:0;
}

.rowDiv-agentNetwork-left {
    width:73%;
    display: inline-block;
    vertical-align: top;
}
.rowDiv-agentNetwork-right {
    margin-left: 1.9em;
    width:25%;
    display: inline-block;
    vertical-align: top;
}

.rowDiv-perSecond-highcharts-display {
    color: #ccc;
    border-color: #ccc;
    border: 1px solid #ccc;
    height: 100%;
    margin-top: 0.5em;
}

.label-display {
    font-weight: 600;
    margin-bottom: 0.5em;;
}

.grid-col-networkSummary {
    border-right: 1px solid #ccc;
    display: inline-block; 
    width: 20%;
    height: 3em;
    word-break: break-word;
    word-wrap: break-word;
    vertical-align: middle;
    text-align: center;
}

.grid-row-networkSummary {
    border:1px solid #ccc;
}

.outerDiv-browserDetails {
    border: 2px solid #ccc;
    border-radius:0.5em;
    background-color:#e8dcfc;
}

.rowDiv-browserDetails {
    margin-left: 10em;
    width: 100%;
}

.innerDev-browserDetails {
    display: inline-block;
    width: 50%;
}

.span-labels-browserDetails {
    display:inline-block; width:30%; 
    background-color:white; 
    padding:0.2em; 
    margin:1em; 
    border-radius:0.2em;
    text-align:center;
}

.span-values-browserDetails {
    display: inline-block;
    width: 30%;
    text-align: left;
}

.greyBorder {
    border: 1px solid #ccc;
}