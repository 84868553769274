@import '../../shared/scss/variables';

.snackbar {
  &__wrapper {
    align-items: center;
    bottom: 0;
    margin: 12px;
    display: flex;
    overflow: hidden;
    position: absolute;
    left: 50%;
    /* allow the width to be calculated dynamically */
    width: auto;
    /* then move the element back again using a transform */
    transform: translateX(-50%);
    z-index: 999;

    .MuiSnackbarContent-action {
      display: flex;
      margin-left: auto;
      padding-left: 10px;
    }
  }

  &__content {
    align-items: center;
    color: #242931;
    display: flex;
    transform: none;
  }
  
  svg {
    fill: #242931;
  }

  &__text {
    .closeButton {
      svg {
        fill: black;
      }
    }
  }
}

.iconVariant {
  opacity: 0.9;
}

.message {
  align-items: center;
  display: flex;
}
