@import './variables';
@import './mixins';
.form {
  &__wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 350px;
    max-width: 100%;
  }

  &__control {
    align-items: center;
    display: flex;
    margin: 5px 0 15px;
    width: 100%;

    &--buttons {
      margin-top: 30px;

      .btnSolid {
        margin-left: 15px;
      }
    }
  }
  &__colouredBtn {
    @include Button-Coloured-Small;
  }

  &__title {
    @include Wizard-Title;
    margin: 5px 0 8px;
  }

  &__label {
    @include Button-Text;
  }

  &__label_color {
    transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  }

}
